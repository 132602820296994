const storeData = new WeakMap()

function hasData(element) {
  return element && storeData.has(element)
}

const mapData = {
  set(element, key, data) {
    if (!hasData(element)) {
      storeData.set(element, {})
    }

    storeData.get(element)[key] = data
  },
  get(element, key) {
    if (!hasData(element)) {
      return null
    }

    const data = storeData.get(element)[key]

    return typeof data === 'undefined' ? null : data
  },
  increment(element, key) {
    let value = this.get(element, key)

    if (value !== null && typeof value !== 'number') {
      throw new TypeError(`Stored value under the "${key}" key is not a number.`)
    }

    value = value === null ? 1 : value + 1

    this.set(element, key, value)

    return value
  },
  delete(element, key) {
    if (!hasData(element)) {
      return
    }

    const dataMap = storeData.get(element)

    // Remove the key

    dataMap[key] = undefined
    delete dataMap[key]

    // Remove element from the store if no meaning values are stored

    const keysCount = Object.keys(dataMap).filter(k => typeof dataMap[k] !== 'undefined').length

    if (keysCount === 0) {
      storeData.delete(element)
    }
  }
}

const Data = {
  /**
   * Stores the key/value pair for the passed object.
   * @param {object} obj - The object.
   * @param {string} key - The data key.
   * @param {any} data - The value to store.
   */
  setData(obj, key, data) {
    if (typeof data === 'undefined' || data === null) {
      mapData.delete(obj, key)
    } else {
      mapData.set(obj, key, data)
    }
  },

  /**
   * Returns the value by key for the passed object.
   * @param {object} obj - The object.
   * @param {string} key - The data key.
   * @returns Stored data or null if the key isn't exists.
   */
  getData(obj, key) {
    return mapData.get(obj, key)
  },

  /**
   * Increments the stored value. If the value does not exist, sets it and returns 1.
   * @param {object} obj - The object.
   * @param {string} key - The data key.
   * @returns {number} The incremented value.
   */
  increment(obj, key) {
    return mapData.increment(obj, key)
  },

  /**
   * Removes the stored data by key.
   * @param {object} obj - The object.
   * @param {string} key - The data key.
   */
  removeData(obj, key) {
    mapData.delete(obj, key)
  }
}

export default Data
