import PREFIX from './_prefix'

function normalizeData(val) {
  if (val === 'true') {
    return true
  }

  if (val === 'false') {
    return false
  }

  if (val === Number(val).toString()) {
    return Number(val)
  }

  if (val === '' || val === 'null') {
    return null
  }

  return val
}

function normalizeDataKey(key) {
  return key.replace(/[A-Z]/g, chr => `-${chr.toLowerCase()}`)
}

function isElementOrDocument(obj) {
  return Boolean(obj) && (obj.nodeType === Node.ELEMENT_NODE || obj.nodeType === Node.DOCUMENT_NODE)
}

function addClasses(element, classes) {
  if (!Dom.isElement(element)) return

  if (Array.isArray(classes)) {
    classes.forEach(cls => element.classList.add(cls))
  } else {
    element.classList.add(classes)
  }
}

function removeClasses(element, classes) {
  if (!Dom.isElement(element)) return

  if (Array.isArray(classes)) {
    classes.forEach(cls => element.classList.remove(cls))
  } else {
    element.classList.remove(classes)
  }
}

const Dom = {
  /**
   * Returns true if the passed object is an HTML element.
   * @param {*} obj - An object.
   * @returns {boolean}
   */
  isElement(obj) {
    return Boolean(obj) && obj.nodeType === Node.ELEMENT_NODE
  },

  /**
   * Returns an array of HTML elements that match the passed selectors.
   * @param {HTMLElement|Document} element - The target HTML element or Document object.
   * @param {...string} selectors - DOMString selectors to match against.
   * @returns {HTMLElement[]}
   */
  find(element, ...selectors) {
    if (!isElementOrDocument(element)) {
      return []
    }

    let result = []

    selectors.forEach(selector => {
      result = result.concat([...element.querySelectorAll(selector)])
    })

    return result
  },

  /**
   * Returns an HTML element that match the passed selector.
   * @param {HTMLElement|Document} element - The target HTML element or Document object.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement|null}
   */
  findOne(element, selector) {
    if (!isElementOrDocument(element)) {
      return null
    }

    return element.querySelector(selector) || null
  },

  /**
   * Returns the element's direct parent node. If selector is specified, returns the parent
   * node if it matches the selector, otherwise returns null.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} [selector] - (optional) A DOMString selector to match against.
   * @returns {HTMLElement|null}
   */
  parent(element, selector) {
    if (!this.isElement(element) || !element.parentNode) {
      return null
    }

    const parent = element.parentNode

    if (selector) {
      return this.isElement(parent) && parent.matches(selector) ? parent : null
    }

    return parent
  },

  /**
   * Returns the first parent node that match the selector. If the parent node isn't found, returns null.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement|null}
   */
  findParent(element, selector) {
    if (!this.isElement(element)) {
      return null
    }

    let parent = element.parentNode

    while (this.isElement(parent) && !parent.matches(selector)) {
      parent = parent.parentNode
    }

    return this.isElement(parent) ? parent : null
  },

  /**
   * Returns an array of parent nodes that match the selector.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement[]}
   */
  findParents(element, selector) {
    if (!this.isElement(element)) {
      return []
    }

    const parents = []

    let parent = element.parentNode
    while (this.isElement(parent)) {
      if (parent.matches(selector)) {
        parents.push(parent)
      }

      parent = parent.parentNode
    }

    return parents
  },

  /**
   * Returns true if the element has a parent node that match the selector. Searches over the full parent DOM tree.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {boolean}
   */
  hasParent(element, selector) {
    return Boolean(this.findParent(element, selector))
  },

  /**
   * Returns the first direct child element that match the passed selector. If the child element isn't found, return null.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement|null}
   */
  findChild(element, selector) {
    if (!this.isElement(element)) {
      return null
    }

    const child = [].concat(...element.children)
      .find(el => this.isElement(el) && el.matches(selector))

    return child || null
  },

  /**
   * Returns an array of direct child elements that match the passed selector.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement[]}
   */
  findChildren(element, selector) {
    if (!this.isElement(element)) {
      return []
    }

    return [].concat(...element.children)
      .filter(el => this.isElement(el) && el.matches(selector))
  },

  /**
   * Returns an HTML element prior to the specified one and matching the passed selector. If the element isn't found, returns null.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement|null}
   */
  prev(element, selector) {
    if (!this.isElement(element)) {
      return null
    }

    let previous = element.previousElementSibling

    while (previous) {
      if (this.isElement(previous) && (!selector || previous.matches(selector))) {
        return previous
      }

      previous = previous.previousElementSibling
    }

    return null
  },

  /**
   * Returns an HTML element following to the specified one and matching the passed selector. If the element isn't found, returns null.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} selector - A DOMString selector to match against.
   * @returns {HTMLElement|null}
   */
  next(element, selector) {
    if (!this.isElement(element)) {
      return null
    }

    let next = element.nextElementSibling

    while (next) {
      if (this.isElement(next) && (!selector || next.matches(selector))) {
        return next
      }

      next = next.nextElementSibling
    }

    return null
  },

  /**
   * Adds the specified class or each class in the array of classes to
   * the target element or each element in the array of target elements.
   * @param {HTMLElement|HTMLElement[]} element - The target HTML element or array of elements.
   * @param {string|string[]} className - Class name or array of class names to add.
   */
  addClass(element, className) {
    if (Array.isArray(element)) {
      element.forEach(el => addClasses(el, className))
    } else {
      addClasses(element, className)
    }
  },

  /**
   * Removes the specified class or each class in the array of classes from the
   * target element or each element in the array of target elements.
   * @param {HTMLElement|HTMLElement[]} element - The target HTML element or array of elements.
   * @param {string|string[]} className - Class name or array of class names to remove.
   */
  removeClass(element, className) {
    if (Array.isArray(element)) {
      element.forEach(el => removeClasses(el, className))
    } else {
      removeClasses(element, className)
    }
  },

  /**
   * Returns true if the target element has the specified class. Otherwise returns false.
   * @param {HTMLElement} element - The target HTML element.
   * @param {string} className - Class to check.
   */
  hasClass(element, className) {
    return this.isElement(element) && element.classList.contains(className)
  },

  /**
   * Returns true if the target element has all specified classes. Otherwise returns false.
   * @param {HTMLElement} element - The target HTML element.
   * @param {...string} classes - Classes to check.
   * @returns {boolean}
   */
  hasClasses(element, ...classes) {
    return this.isElement(element) && classes.every(cls => element.classList.contains(cls))
  },

  /**
   * Returns true if the target element has at least one of the specified classes. Otherwise returns false.
   * @param {HTMLElement} element - The target HTML element.
   * @param {...string} classes - Classes to check.
   * @returns {boolean}
   */
  hasSomeClasses(element, ...classes) {
    return this.isElement(element) && classes.some(cls => element.classList.contains(cls))
  },

  /**
   * Creates an HTML element from the markup string and returns it. Returns null if the markup is not correct.
   * @param {string} markup - The markup string.
   * @returns {HTMLElement|null}
   */
  createElement(markup) {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = markup

    const nodes = [].concat(...wrapper.children)
    let element = null

    for (const node of nodes) {
      if (this.isElement(node)) {
        element = node
        break
      }
    }

    if (element) wrapper.removeChild(element)

    return element
  },

  /**
   * Returns the value of a specified attribute on the target element. Returns defaultValue if the attribute is not found.
   * @param {HTMLElement} element - The target element.
   * @param {string} key - The attribute name.
   * @param {*} [defaultValue=null] - (optional) The default return value. Defaults to null.
   * @returns {*}
   */
  getAttribute(element, key, defaultValue = null) {
    const attrKey = normalizeDataKey(key)
    return element.hasAttribute(attrKey) ?
      element.getAttribute(attrKey) :
      defaultValue
  },

  /**
   * Sets the value of an attribute on the target element.
   * @param {HTMLElement} element - The target element.
   * @param {string} key - The attribute name.
   * @param {*} value - The value to assign to the attribute.
   */
  setAttribute(element, key, value) {
    element.setAttribute(normalizeDataKey(key), value)
  },

  /**
   * Removes the attribute with the specified name from the target element.
   * @param {HTMLElement} element - The target element.
   * @param {string} key - The attribute name.
   */
  removeAttribute(element, key) {
    element.removeAttribute(normalizeDataKey(key))
  },

  /**
   * Returns the value of a data attribute with namespace on the target element. Returns defaultValue if the attribute is not found.
   * @param {HTMLElement} element - The target element.
   * @param {string} key - The data attribute name.
   * @param {*} [defaultValue=null] - (optional) The default return value. Defaults to null.
   * @returns {*}
   */
  getDataAttribute(element, key, defaultValue = null) {
    const attrKey = `data-${PREFIX}-${normalizeDataKey(key)}`
    return element.hasAttribute(attrKey) ?
      normalizeData(element.getAttribute(attrKey)) :
      normalizeData(defaultValue)
  },

  /**
   * Sets the value of a "data-" attribute with namespace on the target element.
   * @param {HTMLElement} element - The target element.
   * @param {string} key - The data attribute name.
   * @param {*} value - The value to assign to the attribute.
   */
  setDataAttribute(element, key, value) {
    element.setAttribute(`data-${PREFIX}-${normalizeDataKey(key)}`, value)
  },

  /**
   * Removes the "data-" attribute with namespace and the specified name from the target element.
   * @param {HTMLElement} element - The target element.
   * @param {string} key - The data attribute name.
   */
  removeDataAttribute(element, key) {
    element.removeAttribute(`data-${PREFIX}-${normalizeDataKey(key)}`)
  },

  /**
   * Returns an object with all values of "data-" attributes with namespace on the target element.
   * @param {HTMLElement} element - The target element.
   * @returns {object}
   */
  getDataAttributes(element) {
    if (!this.isElement(element)) {
      return {}
    }

    const attributes = {}

    Object.keys(element.dataset)
      .filter(key => key.startsWith(PREFIX))
      .forEach(key => {
        let pureKey = key.replace(new RegExp(`^${PREFIX}`), '')
        pureKey = pureKey.charAt(0).toLowerCase() + pureKey.slice(1)
        attributes[pureKey] = normalizeData(element.dataset[key])
      })

    return attributes
  }
}

export default Dom
