import { normalizeJQueryPluginName } from './lib/_utils'
import PREFIX from './lib/_prefix'
import Dom from './lib/_dom'
import Listeners from './lib/_listeners'
import Data from './lib/_data'
import BaseComponent from './lib/_base-component'
import { defineJQueryPlugin, typeCheckConfig } from 'bootstrap/js/src/util/index'
import Dropdown from 'bootstrap/js/src/dropdown'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'hover-dropdown'
const DATA_KEY = `${PREFIX}-${NAME}`
const EVENT_KEY = `.${DATA_KEY}`

const CLASS_NAME_SHOW = 'show'
const ATTRIBUTE_BS_DATA_TOGGLE = 'data-bs-toggle'

const SELECTOR_MENU = '.dropdown-menu'

const EVENT_NAME_MOUSEIN = `mouseenter${EVENT_KEY}`
const EVENT_NAME_MOUSEOUT = `mouseleave${EVENT_KEY}`
const EVENT_NAME_DROPDOWN_SHOWN = `shown.bs.dropdown${EVENT_KEY}`
const EVENT_NAME_DROPDOWN_HIDE = `hide.bs.dropdown${EVENT_KEY}`
const EVENT_NAME_DROPDOWN_HIDDEN = `hidden.bs.dropdown${EVENT_KEY}`

const Default = {
  timeout: 150
}

const DefaultType = {
  timeout: 'number'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class HoverDropdown extends BaseComponent {
  constructor(element, config = {}) {
    super(element)

    const { timeout, ...bsConfig } = this._getConfig(config)
    this._timeout = timeout

    this._menu = Dom.next(element, SELECTOR_MENU)
    this._overToggle = false
    this._shown = Dom.hasClass(element, CLASS_NAME_SHOW)
    this._timeoutId = null

    if (!this._menu) {
      throw new Error(`${NAME.toUpperCase()}: ".dropdown-menu" element is not found.`)
    }

    Dom.setAttribute(element, ATTRIBUTE_BS_DATA_TOGGLE, 'dropdown')
    this._instance = new Dropdown(element, bsConfig)

    this._addEventListeners()
  }

  // Getters

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // Bridge to default BS Dropdown methods

  toggle() {
    return this._instance.toggle()
  }

  show() {
    return this._instance.show()
  }

  hide() {
    return this._instance.hide()
  }

  update() {
    return this._instance.update()
  }

  getInstance() {
    return this._instance.getInstance()
  }

  dispose() {
    this._clearTimeout()
    Listeners.clear(this._element, EVENT_KEY)
    Listeners.clear(this._menu, EVENT_KEY)
    this._instance.dispose()
    super.dispose()
    this._instance = null
    this._menu = null
  }

  // Private

  _getConfig(config) {
    config = {
      ...Dropdown.Default,
      ...this.constructor.Default,
      ...Dom.getDataAttributes(this._element),
      ...config
    }

    typeCheckConfig(NAME, config, {
      ...Dropdown.DefaultType,
      ...this.constructor.DefaultType
    })

    return config
  }

  _addEventListeners() {
    Listeners.add(this._element, EVENT_NAME_MOUSEIN, () => {
      this._overToggle = true
      this._mouseIn()
    })

    Listeners.add(this._menu, EVENT_NAME_MOUSEIN, () => {
      this._mouseIn()
    })

    Listeners.add(this._element, EVENT_NAME_MOUSEOUT, () => {
      this._overToggle = false
      this._mouseOut()
    })

    Listeners.add(this._menu, EVENT_NAME_MOUSEOUT, () => {
      this._mouseOut()
    })

    Listeners.add(this._element, EVENT_NAME_DROPDOWN_SHOWN, () => {
      this._updateShown(true)
    })

    Listeners.add(this._element, EVENT_NAME_DROPDOWN_HIDDEN, () => {
      this._updateShown(false)
    })

    // Prevent dropdown close by button click if opened
    Listeners.add(this._element, EVENT_NAME_DROPDOWN_HIDE, e => {
      if (this._shown && this._overToggle) {
        e.preventDefault()
      }
    })
  }

  _updateShown(shown) {
    this._shown = shown
  }

  _isShown() {
    return this._shown
  }

  _clearTimeout() {
    if (this._timeoutId) {
      window.clearTimeout(this._timeoutId)
      this._timeoutId = null
    }
  }

  _mouseIn() {
    this._clearTimeout()
    if (this._isShown()) {
      return
    }

    this.show()
  }

  _mouseOut() {
    this._clearTimeout()
    if (!this._isShown()) {
      return
    }

    this._timeoutId = window.setTimeout(() => {
      this._clearTimeout()
      this.hide()
    }, this._timeout)
  }

  // Static

  static hoverDropdownInterface(element, config) {
    let data = Data.getData(element, DATA_KEY)
    const _config = typeof config === 'object' ? config : null

    if (!data) {
      data = new HoverDropdown(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static jQueryInterface(config) {
    return this.each(function () {
      HoverDropdown.hoverDropdownInterface(this, config)
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .hoverDropdown to jQuery only if jQuery is present
 */

defineJQueryPlugin(normalizeJQueryPluginName(NAME), HoverDropdown)

export default HoverDropdown
