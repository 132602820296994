import { triggerTransitionEnd, isRTL as bsIsRTL } from 'bootstrap/js/src/util'
import EventHandler from 'bootstrap/js/src/dom/event-handler'
import cloneDeep from 'lodash.clonedeep'

/**
 * Escapes HTML special characters.
 * @param {string} str - The source string.
 * @returns {string}
 */
 export function escapeHTML(str) {
  var chars = {
    34: '&quot;', // "
    38: '&amp;', // &
    39: '&#39;', // '
    60: '&lt;', // <
    62: '&gt;' // >
  }

  return str.replace(/["'&<>]/g, function(m) {
    return chars[m.charCodeAt(0)] || m;
  })
}

/**
 * Converts the passed string to a camelCase string.
 * @param {string} name - The plugin name, e.g. some-plugin.
 * @returns {string}
 */
export function normalizeJQueryPluginName(name) {
  return name.toLowerCase().replace(/-([a-z])/gi, (_, $1) => {
    return $1.toUpperCase()
  })
}

/**
 * Fires an event on the target element.
 * @param {HTMLElement|Window} target - The target element.
 * @param {string} eventName - The event name.
 * @param {Object} [data] - (optional) Additional data that will be exposed to Event object.
 * @returns {Event}
 */
export function fireEvent(target, eventName, data) {
  return EventHandler.trigger(target, eventName, data)
}

/**
 * Emulates transitionend event.
 * @param {HTMLElement} element - The target element.
 * @param {number} duration - The transition duration.
 */
export function emulateTransitionEnd(element, duration) {
  const TRANSITION_END = 'transitionend'

  let called = false
  const durationPadding = 5
  const emulatedDuration = duration + durationPadding

  function listener() {
    called = true
    element.removeEventListener(TRANSITION_END, listener)
  }

  element.addEventListener(TRANSITION_END, listener)
  setTimeout(() => {
    if (!called) {
      triggerTransitionEnd(element)
    }
  }, emulatedDuration)
}

/**
 * Animates scroll position of the element.
 * @param {HTMLElement} element - The target element.
 * @param {number} to - The targeted scroll position.
 * @param {boolean} animate - Enable animation.
 * @param {string} property - Scroll property, e.g. "scrollTop", "scrollLeft".
 * @param {number} [duration=200] - Animation duration.
 */
export function scrollTo(element, to, animate, property, duration = 200) {
  if (element[property] === to) return Promise.resolve()

  if (!animate || !window.requestAnimationFrame) {
    element[property] = to
    return Promise.resolve()
  }

  // t = current time; b = start value; c = change in value; d = duration
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2

    if (t < 1) return (c / 2 * t * t) + b

    t--
    return (-c / 2 * ((t * (t - 2)) - 1)) + b
  }

  const start = element[property]

  const change = to - start
  let startDate

  return new Promise(resolve => {
    const animateScroll = timestamp => {
      if (typeof startDate === 'undefined') {
        startDate = timestamp
      }

      const currentTime = timestamp - startDate

      element[property] = easeInOutQuad(currentTime, start, change, duration)

      if (currentTime < duration) {
        return window.requestAnimationFrame(animateScroll)
      }

      element[property] = to
      resolve()
    }

    window.requestAnimationFrame(animateScroll)
  })
}

/**
 * Executes function and returns a Promise resolved with the result.
 * If the function returns a Promise, it will be returned as is.
 * If the function returns `false`, Promise.reject() will be returned.
 * @param {HTMLElement} element - The function to execute.
 * @param {number} to - The function params.
 * @param {number} [duration=200] - Animation duration.
 * @returns {Promise}
 */
export function promisify(fn, ...args) {
  const result = fn(...args)
  return result instanceof Promise ?
    result :
    (result === false ? Promise.reject() : Promise.resolve(result))
}

let rootStyle = null
export function getCSSVar(propName, type = 'string', style = null) {
  if (!style) {
    if (!rootStyle) {
      rootStyle = window.getComputedStyle(document.documentElement)
    }

    style = rootStyle
  }

  const value = style.getPropertyValue(propName)?.replace(/^[\s"']+|[\s"']+$/g, '') || null

  if (type === 'number') {
    const numValue = Number.parseInt(value, 10)

    if (Number.isNaN(numValue)) {
      throw new TypeError(
        `"${propName}" CSS variable is not defined or not a valid number.`
      )
    }

    return numValue
  } else if (type === 'boolean') {
    if (value === 'true') {
      return true
    } else if (value === 'false') {
      return false
    }

    throw new TypeError(
      `"${propName}" CSS variable is not defined or not a valid boolean (true / false).`
    )
  } else if (type === 'string' && !value) {
    throw new TypeError(
      `"${propName}" CSS variable is not defined.`
    )
  }

  return value
}

export function resetCSSVars() {
  rootStyle = null
}

/**
 * Returns a new object that contains specified props from the source object.
 * @param {Object} sourceObject - The source object.
 * @param {string[]} props - An array of keys to select.
 * @returns {Object}
 */
 export function pickObjectProps(sourceObject, props) {
  sourceObject = cloneDeep(sourceObject)
  const resultObject = {}

  if (Array.isArray(props)) {
    props.forEach(prop => {
      if (typeof prop !== 'string' || !prop || typeof sourceObject[prop] === 'undefined') return

      resultObject[prop] = sourceObject[prop]
    })
  }

  return resultObject
}

export function isRTL() {
  return bsIsRTL()
}
