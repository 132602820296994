const listeners = new WeakMap()

function getNamespace(event) {
  if (event.includes('.')) {
    const parts = event.split('.')
    return [parts.slice(0, -1).join('.'), parts.slice(-1)[0]]
  }

  return [event, null]
}

function findListener(element, event, namespace, handler) {
  if (!element || !listeners.has(element)) {
    return
  }

  const listener = listeners.get(element).find(l => {
    return l.event === event && l.namespace === namespace && l.handler === handler
  })

  return listener || null
}

function delegationHandler(element, selector, fn) {
  return function (event) {
    const domElements = element.querySelectorAll(selector)

    for (let { target } = event; target && target !== this; target = target.parentNode) {
      for (let i = domElements.length; i--;) {
        if (domElements[i] === target) {
          event.delegateTarget = target
          return fn.apply(target, [event])
        }
      }
    }
  }
}

const mapListeners = {
  add(element, eventName, handler, options, oneOff) {
    if (!listeners.has(element)) {
      listeners.set(element, [])
    }

    const [event, namespace] = getNamespace(eventName)

    const resultHandler = oneOff ?
      function (e) {
        mapListeners.remove(element, event, namespace, resultHandler)
        handler.apply(element, [e])
      } :
      handler

    element.addEventListener(event, resultHandler, options)

    if (oneOff) {
      listeners.get(element).push({ event, namespace, handler: resultHandler, originalHandler: handler, options })
    } else {
      listeners.get(element).push({ event, namespace, handler: resultHandler, options })
    }
  },

  addDelegated(element, selector, eventName, handler) {
    if (!listeners.has(element)) {
      listeners.set(element, [])
    }

    const [event, namespace] = getNamespace(eventName)
    const delegatedHandler = delegationHandler(element, selector, handler)

    element.addEventListener(event, delegatedHandler)
    listeners.get(element).push({ event, namespace, handler: delegatedHandler, originalHandler: handler })
  },

  remove(element, event, namespace, handler) {
    const listener = findListener(element, event, namespace, handler)

    if (!listener) {
      return
    }

    const elementListeners = listeners.get(element)

    element.removeEventListener(listener.event, listener.handler, listener.options)
    elementListeners.splice(elementListeners.indexOf(listener), 1)

    if (!elementListeners.length) {
      listeners.delete(element)
    }
  },

  clear(element, eventName, handler) {
    if (!element || !listeners.has(element)) {
      return
    }

    const elementListeners = listeners.get(element)
    let targetListeners

    if (!eventName) {
      targetListeners = [...elementListeners]
    } else if (eventName.startsWith('.')) {
      const namespace = eventName.replace(/^\./, '')
      targetListeners = elementListeners.filter(listener => listener.namespace === namespace)
    } else {
      const [event, namespace] = getNamespace(eventName)

      targetListeners = namespace ?
        elementListeners.filter(listener => listener.event === event && listener.namespace === namespace) :
        elementListeners.filter(listener => listener.event === event)
    }

    targetListeners.forEach(listener => {
      if (handler && listener.handler !== handler && (!listener.originalHandler || listener.originalHandler !== handler)) return
      element.removeEventListener(listener.event, listener.handler, listener.options)
      elementListeners.splice(elementListeners.indexOf(listener), 1)
    })

    if (!elementListeners.length) {
      listeners.delete(element)
    }
  }
}

const Listeners = {
  /**
   * Attaches an event listener to the element.
   * @param {HTMLElement|Window} element - The target element.
   * @param {string} event - The event name. You can use a simple event name (e.g. "click") or with namespace (e.g. "click.namespace")
   * @param {function} handler - The event handler.
   * @param {Object} [options] - (optional) An object that will be passed to the .addEventListener() method.
   */
  add(element, event, handler, options) {
    mapListeners.add(element, event, handler, options, false)
  },

  /**
   * Attaches an event listener that will be automatically unbound after the first call.
   * @param {HTMLElement|Window} element - The target element.
   * @param {string} event - The event name. You can use a simple event name (e.g. "click") or with namespace (e.g. "click.namespace")
   * @param {function} handler - The event handler.
   * @param {Object} [options] - (optional) An object that will be passed to the .addEventListener() method.
   */
  oneOff(element, event, handler, options) {
    mapListeners.add(element, event, handler, options, true)
  },

  /**
   * Attaches a delegated event listener that provokes the handler only when the target element is
   * matching the selector. You can access the delegated element using Event's .delegateTarget
   * property (e.g. e.delegateTarget)
   * @param {HTMLElement} element - The parent element.
   * @param {string} selector - The selector for targeted elements (e.g. ".some-class").
   * @param {string} event - The event name. You can use a simple event name (e.g. "click") or with namespace (e.g. "click.namespace")
   * @param {function} handler - The event handler.
   */
  addDelegated(element, selector, event, handler) {
    mapListeners.addDelegated(element, selector, event, handler)
  },

  /**
   * Removes event listeners by name, namespace or specific name + namespace.
   * If no event name nor namespace is passed, then all listeners will be removed.
   * @param {HTMLElement|Window} element - The target element.
   * @param {string} [event] - (optional) The event name, namespace or event name with namespace, e.g. "click", ".namespace", "click.namespace".
   * @param {function} [handler] - (optional) The event handler.
   */
  clear(element, event, handler) {
    mapListeners.clear(element, event, handler)
  }
}

export default Listeners
