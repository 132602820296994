import { normalizeJQueryPluginName, getCSSVar } from './lib/_utils'
import PREFIX from './lib/_prefix'
import Dom from './lib/_dom'
import Listeners from './lib/_listeners'
import Data from './lib/_data'
import BaseComponent from './lib/_base-component'
import Dropdown from 'bootstrap/js/src/dropdown'
import { defineJQueryPlugin, typeCheckConfig } from 'bootstrap/js/src/util/index'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'nested-dropdowns'
const DATA_KEY = `${PREFIX}-${NAME}`
const EVENT_KEY = `.${DATA_KEY}`

const CLASS_NAME_SHOW = 'show'
const CLASS_NAME_DISABLED = 'disabled'
const CLASS_NAME_SUB_DROPDOWN = 'sub-dropdown'
const CLASS_NAME_MENU = 'dropdown-menu'

const SELECTOR_TOGGLE = '[data-bs-toggle=dropdown]'
const SELECTOR_ITEM = '.dropdown-item'
const SELECTOR_SUB_DROPDOWN = `.${CLASS_NAME_SUB_DROPDOWN}`
const SELECTOR_SUB_DROPDOWN_MENU = `.${CLASS_NAME_SUB_DROPDOWN}-menu`

const EVENT_NAME_CLICK = `click${EVENT_KEY}`
const EVENT_NAME_MOUSEIN = `mouseover${EVENT_KEY}`
const EVENT_NAME_MOUSEOUT = `mouseout${EVENT_KEY}`
const EVENT_FOCUSIN = `focusin${EVENT_KEY}`
const EVENT_FOCUSOUT = `focusout${EVENT_KEY}`
const EVENT_NAME_DROPDOWN_HIDDEN = `hidden.bs.dropdown${EVENT_KEY}`

const Default = {
  timeout: 150
}

const DefaultType = {
  timeout: 'number'
}

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class NestedDropdowns extends BaseComponent {
  constructor(element, config = {}) {
    super(element)

    if (!Dom.hasClass(this._element, CLASS_NAME_MENU)) {
      throw new Error(`${NAME.toUpperCase()}: The plugin must be initialized on a ".dropdown-menu" element.`)
    }

    this._toggle = Dom.prev(this._element, SELECTOR_TOGGLE)

    if (!this._toggle) {
      throw new Error(`${NAME.toUpperCase()}: The "${SELECTOR_TOGGLE}" element is not found.`)
    }

    const { timeout } = this._getConfig(config)

    this._timeout = timeout
    this._curMenu = null
    this._layoutBreakpoint = getCSSVar(`--${PREFIX}-layout-breakpoint`, 'number'),

    this._addEventListeners()
  }

  // Getters

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  dispose() {
    this._hideAll()

    Listeners.clear(this._toggle, EVENT_KEY)
    Listeners.clear(this._element, EVENT_KEY)

    super.dispose()

    this._toggle = null
    this._curMenu = null
  }

  // Private

  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...Dom.getDataAttributes(this._element),
      ...config
    }

    typeCheckConfig(NAME, config, {
      ...this.constructor.DefaultType
    })

    return config
  }

  _addEventListeners() {
    // Click on a sub-dropdown
    Listeners.addDelegated(this._element, SELECTOR_SUB_DROPDOWN, EVENT_NAME_CLICK, e => {
      e.preventDefault()
      e.stopPropagation()

      const menu = this._getMenu(e.delegateTarget)

      if (this._isDisabled(menu)) {
        return
      }

      if (this._isMobileView()) {
        if (this._isShown(menu)) {
          this._hide(menu)
        } else {
          this._curMenu = menu
          this._show(menu)
        }
      } else {
        this._curMenu = menu
        this._show(menu)
      }
    })

    // Click on an item within sub-dropdown
    Listeners.addDelegated(this._element, `${SELECTOR_SUB_DROPDOWN} ${SELECTOR_ITEM}`, EVENT_NAME_CLICK, e => {
      e.stopPropagation()

      const ddInstance = Dropdown.getInstance(this._toggle)

      if (ddInstance) {
        const { autoClose } = ddInstance._config

        if (typeof autoClose === 'undefined' || autoClose === true || autoClose === 'inside') {
          ddInstance.hide()
        }
      }
    })

    // Close all submenus on dropdown show/hide
    Listeners.add(this._toggle, EVENT_NAME_DROPDOWN_HIDDEN, () => this._hideAll())

    // Show sub-dropdowns

    const showDropdownEvent = e => {
      if (this._isMobileView()) return

      this._curMenu = this._getMenu(e.delegateTarget)

      if (this._isDisabled(this._curMenu)) {
        return
      }

      this._show(this._curMenu)
    }

    Listeners.addDelegated(this._element, SELECTOR_SUB_DROPDOWN, EVENT_NAME_MOUSEIN, showDropdownEvent)
    Listeners.addDelegated(this._element, SELECTOR_SUB_DROPDOWN, EVENT_FOCUSIN, showDropdownEvent)

    // Hide sub-dropdowns

    const hideDropdownEvent = type => {
      return e => {
        if (this._isMobileView()) return

        this._curMenu = null

        const el = this._getMenu(e.delegateTarget)
        this._clearTimeout(el)

        if (this._isShown(el)) {
          this._setTimeout(el, type, () => this._hide(el))
        }
      }
    }

    Listeners.addDelegated(this._element, SELECTOR_SUB_DROPDOWN, EVENT_NAME_MOUSEOUT, hideDropdownEvent('mouse'))
    Listeners.addDelegated(this._element, SELECTOR_SUB_DROPDOWN, EVENT_FOCUSOUT, hideDropdownEvent('focus'))
  }

  _isMobileView() {
    return window.innerWidth < this._layoutBreakpoint
  }

  _isShown(el) {
    return Dom.hasClass(el, CLASS_NAME_SHOW)
  }

  _isDisabled(el) {
    return Dom.hasClass(el, CLASS_NAME_DISABLED)
  }

  _show(menu) {
    this._clearTimeout(menu)

    if (this._isShown(menu)) return

    Dom.addClass(menu, CLASS_NAME_SHOW)

    const parentSubMenu = Dom.findParent(menu, SELECTOR_SUB_DROPDOWN)
    let parentMenu

    if (parentSubMenu && this._element.contains(parentSubMenu)) {
      parentMenu = Dom.findChild(parentSubMenu, SELECTOR_SUB_DROPDOWN_MENU)
      this._clearTimeout(parentSubMenu)
    }

    // Close other dropdowns
    Dom.findChildren(parentMenu || this._element, `${SELECTOR_SUB_DROPDOWN}.${CLASS_NAME_SHOW}`)
      .forEach(dd => {
        if (dd !== menu) this._hide(dd)
      })
  }

  _hide(menu) {
    this._clearTimeout(menu)

    if (!this._isShown(menu)) return

    const openedMenus = [
      menu,

      // Parent menus
      ...Dom.findParents(menu, `${SELECTOR_SUB_DROPDOWN}.${CLASS_NAME_SHOW}`)
        .filter(t => !this._curMenu || (this._curMenu.contains(t) && this._curMenu !== t)),

      // Children menus
      ...Dom.find(menu, `${SELECTOR_SUB_DROPDOWN}.${CLASS_NAME_SHOW}`)
    ]

    openedMenus.forEach(t => {
      this._clearTimeout(t)
      Dom.removeClass(t, CLASS_NAME_SHOW)
    })
  }

  _hideAll() {
    this._curMenu = null

    Dom.find(this._element, `${SELECTOR_SUB_DROPDOWN}.${CLASS_NAME_SHOW}`)
      .forEach(menu => {
        this._clearTimeout(menu)
        Dom.removeClass(menu, CLASS_NAME_SHOW)
      })
  }

  _setTimeout(el, key, cb) {
    el[`${DATA_KEY}-${key}-timeout-id`] = window.setTimeout(cb, this._timeout)
  }

  _clearTimeout(el) {
    const mouseTimeoutId = el[`${DATA_KEY}-mouse-timeout-id`] || null
    const focusTimeoutId = el[`${DATA_KEY}-focus-timeout-id`] || null

    if (mouseTimeoutId) {
      window.clearTimeout(mouseTimeoutId)
      el[`${DATA_KEY}-mouse-timeout-id`] = null
    }

    if (focusTimeoutId) {
      window.clearTimeout(focusTimeoutId)
      el[`${DATA_KEY}-focus-timeout-id`] = null
    }
  }

  _getMenu(el) {
    let menu = null

    if (Dom.hasClass(el, CLASS_NAME_SUB_DROPDOWN)) {
      menu = el
    } else {
      menu = Dom.parent(el, SELECTOR_SUB_DROPDOWN)
    }

    if (!menu) {
      throw new Error(`${NAME.toUpperCase()}: The "${SELECTOR_SUB_DROPDOWN}" element is not found.`)
    }

    return menu
  }

  // Static

  static nestedDropdownsInterface(element, config) {
    let data = Data.getData(element, DATA_KEY)
    const _config = typeof config === 'object' ? config : null

    if (!data) {
      data = new NestedDropdowns(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static jQueryInterface(config) {
    return this.each(function () {
      NestedDropdowns.nestedDropdownsInterface(this, config)
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .nestedDropdowns to jQuery only if jQuery is present
 */

defineJQueryPlugin(normalizeJQueryPluginName(NAME), NestedDropdowns)

export default NestedDropdowns
