import 'bootstrap/js/src/dropdown'
import Collapse from 'bootstrap/js/src/collapse'
import HoverDropdown from './vendor/hover-dropdown'
import NestedDropdowns from './vendor/nested-dropdowns'

const htmlEl = document.documentElement;
const body = document.querySelector('body')
let currentLocation = document.location.href

// Copy navbar
const menu = document.getElementById('page_main_menu');
if (menu) {
  const togglePoint = menu.offsetHeight + menu.offsetTop - 50
  const menuClone = document.createElement('div')
  menuClone.className = 'container'
  menuClone.innerHTML = menu.innerHTML
  menuClone.querySelector('.navbar-collapse').id = 'page_main_menu_collapse_clone'
  menuClone.querySelector('.navbar-toggler').setAttribute('data-bs-target', 'page_main_menu_collapse_clone')
  menuClone.querySelector('.navbar-toggler').setAttribute('aria-controls', 'page_main_menu_collapse_clone')
  const wrapper = document.createElement('div')
  wrapper.className = 'page_main_menu-fixed'
  wrapper.appendChild(menuClone)
  menu.parentNode.insertBefore(wrapper, menu)
  let shown = false

  const menuCollapse = new Collapse('#page_main_menu_collapse_clone', {toggle: false})
  menuClone.querySelector('.navbar-toggler').addEventListener('click', function(e) {
    e.preventDefault()
    e.stopPropagation()
    menuCollapse.toggle()
  })

  document.addEventListener('scroll', function() {
    if (htmlEl.scrollTop > togglePoint) {
      if (!shown) return
      body.classList.add('menu-fixed')
      shown = false
    } else {
      if (shown) return
      body.classList.remove('menu-fixed')
      shown = true
    }
  }, {passive: true})
}

// Initialize hover dropdowns
const hDropdownBtns = [...document.querySelectorAll('[data-px-toggle="hover-dropdown"]')]
hDropdownBtns.map(toggle => new HoverDropdown(toggle))

// Initialize nested dropdowns
const nestedDropdowns = [...document.querySelectorAll('.nested-dropdowns')]
nestedDropdowns.map(menu => new NestedDropdowns(menu))

const ajaxPagination = document.querySelector('.ajax-pagination');

const loadAjaxPagination = (link, setState = false) => {
  const url = link + (link.indexOf('?') == -1 ? '?' : '&') + 'ajax=1'
  const el = document.querySelector('.ajax-pagination');

  try {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`)
        }
        return response.text()
      })
      .then((text) => {
        ajaxPagination.innerHTML = text
        ajaxPagination.classList.remove('loading')

        if (setState && window.history && typeof window.history.pushState == 'function') {
          currentLocation = link;
          window.history.pushState({ type: 'pagination', link }, "", link)
        }

        try {
          document.getElementById('page_content').scrollIntoView()
        } catch(e) {}
      })
      .catch((e) => {
        console.log(e)
        window.location = link
      });
  } catch (e) {
    console.log(e)
    window.location = link
  }
}

if (ajaxPagination) {
  ajaxPagination.addEventListener('click', e => {
    if (!e.target.classList.contains('page-link')) return
  
    const link = e.target.getAttribute('href')
    if (!link) return
  
    e.preventDefault()
    ajaxPagination.classList.add('loading')
    loadAjaxPagination(link, true)
  });
  
  if (window.history && typeof window.history.pushState == 'function') {
    window.addEventListener("popstate", e => {
      if (!e.state || e.state.type !== 'pagination') {
        if (window.location.href != currentLocation) window.location = document.location
        return
      }
      loadAjaxPagination(e.state.link, false)
    })
  }
}

if (document.getElementById('accessibility_panel')) {
  const updateAccessibilitySettings = () => {
    let settings = '';

    settings += body.classList.contains('accessibility-on') ? 'on' : 'off'
    settings += '|'

    if (body.classList.contains('accessibility-fs2')) {
      settings += 'fs2'
    } else if (body.classList.contains('accessibility-fs3')) {
      settings += 'fs3'
    } else {
      settings += 'fs1'
    }
    settings += '|'

    settings += body.classList.contains('accessibility-img-off') ? 'off' : 'on'
    settings += '|'

    if (body.classList.contains('accessibility-bg-white')) {
      settings += 'white'
    } else if (body.classList.contains('accessibility-bg-black')) {
      settings += 'black'
    } else {
      settings += 'off'
    }

    window.localStorage.setItem('accessibilitySettings', settings);
  }

  const toggleAccessibilityPanel = () => {
    if (body.classList.contains('accessibility-on')) {
      body.classList.remove('accessibility-on')
    } else {
      body.classList.add('accessibility-on')
    }
    updateAccessibilitySettings()
  }

  document.getElementById('page_header_accessibility').addEventListener('click', function(e) {
    e.preventDefault()
    toggleAccessibilityPanel()
  })
  document.getElementById('toggle_accessibility_panel').addEventListener('click', function(e) {
    e.preventDefault()
    toggleAccessibilityPanel()
  })

  document.querySelector('.accessibility_panel-fs1').addEventListener('click', function(e) {
    e.preventDefault()
    body.classList.remove('accessibility-fs2')
    body.classList.remove('accessibility-fs3')
    updateAccessibilitySettings()
  })

  document.querySelector('.accessibility_panel-fs2').addEventListener('click', function(e) {
    e.preventDefault()
    body.classList.remove('accessibility-fs3')
    body.classList.add('accessibility-fs2')
    updateAccessibilitySettings()
  })

  document.querySelector('.accessibility_panel-fs3').addEventListener('click', function(e) {
    e.preventDefault()
    body.classList.remove('accessibility-fs2')
    body.classList.add('accessibility-fs3')
    updateAccessibilitySettings()
  })

  document.querySelector('.accessibility_panel-img-off').addEventListener('click', function(e) {
    e.preventDefault()
    if (body.classList.contains('accessibility-img-off')) {
      body.classList.remove('accessibility-img-off')
    } else {
      body.classList.add('accessibility-img-off')
    }
    updateAccessibilitySettings()
  })

  document.querySelector('.accessibility_panel-bg-black').addEventListener('click', function(e) {
    e.preventDefault()
    body.classList.remove('accessibility-bg-white')
    body.classList.add('accessibility-bg-black')
    updateAccessibilitySettings()
  })
  document.querySelector('.accessibility_panel-bg-white').addEventListener('click', function(e) {
    e.preventDefault()
    body.classList.remove('accessibility-bg-black')
    body.classList.add('accessibility-bg-white')
    updateAccessibilitySettings()
  })
  document.querySelector('.accessibility_panel-bg-off').addEventListener('click', function(e) {
    e.preventDefault()
    body.classList.remove('accessibility-bg-black')
    body.classList.remove('accessibility-bg-white')
    updateAccessibilitySettings()
  })
}

const searchBtn = document.getElementById('page_header_search');
if (searchBtn) {
  searchBtn.addEventListener('click', function() {
    if (this.parentNode.classList.contains('show')) {
      this.parentNode.classList.remove('show');
    } else {
      this.parentNode.classList.add('show');
      this.parentNode.querySelector('.ya-site-form__input-text').focus();
    }
  });
}
